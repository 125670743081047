import styles from "./BuyWitheCall.module.scss";
import { Link } from "react-router-dom";
import { PhoneSvg } from "./PhoneSvg";
import { PAGES } from "constants";
import useIsMobile from "hooks/useIsMobile";

const BuyWitheCall = () => {
  const mobile = useIsMobile();
  return (
    <Link
      to={PAGES.BUY_FOR_ME.HREF}
      className={`flex-center flex-column ${styles.linkWrapper} ${
        mobile && styles.mobileVers
      }`}
    >
      <PhoneSvg />
      <span className="fz-10-bold">
        Պատվիրի՛ր <br /> Զանգով
      </span>
    </Link>
  );
};

export default BuyWitheCall;
