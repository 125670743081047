export const PAGES = {
  HOME: { TITLE: null, HREF: "/" },

  PRODUCTS: { TITLE: null, HREF: "/products" },

  LIQUID_ASSORTMENT: { TITLE: "Ակցիա", HREF: "/liquidation_assortment" },
  NOVELTY: { TITLE: "Մանկական", HREF: "/products" },
  SOON: { TITLE: "Նորույթ", HREF: "/novelty" },
  BUY_FOR_ME: { TITLE: "Պատվիրի՛ր զանգով", HREF: "/buy_for_me" },
  CONTACT_US: { TITLE: "Կապ", HREF: "/contact_us" },
  PRICING_POLICY: { TITLE: "Գնային Քաղաքականություն", HREF: "/pricing_policy" },

  SHOP_PRODUCT: { TITLE: null, HREF: "/product/:productName/:productId" },
  CHECKOUT: { TITLE: "ՎՃԱՐՈՒՄ", HREF: "/checkout" },

  PERSONAL_PAGE: { TITLE: "Անձնական էջ", HREF: "/personal_page" },
  ORDER_DETAILS: { TITLE: null, HREF: "/order_details/:order_number" },

  FAVORITES: { TITLE: null, HREF: "/favorites" },
  BASKET: { TITLE: null, HREF: "/basket" },
  LOGIN: { TITLE: null, HREF: "/login" },

  NOT_FOUND: { TITLE: "Page Not Found", HREF: "/*" },
};
