// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_layoutWrapper__djoBJ {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
}
.Layout_layoutWrapper__djoBJ .Layout_content__lMHAS {
  position: relative;
  width: 100%;
  min-height: calc(100% - 40rem);
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout/Layout.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;EACA,aAAA;EACA,gBAAA;AACF;AAAE;EACE,kBAAA;EACA,WAAA;EACA,8BAAA;AAEJ","sourcesContent":[".layoutWrapper {\n  width: 100vw;\n  max-width: 100%;\n  height: 100vh;\n  max-height: 100%;\n  .content {\n    position: relative;\n    width: 100%;\n    min-height: calc(100% - 40rem);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layoutWrapper": `Layout_layoutWrapper__djoBJ`,
	"content": `Layout_content__lMHAS`
};
export default ___CSS_LOADER_EXPORT___;
