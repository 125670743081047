import { Outlet } from "react-router";
import { Suspense, lazy } from "react";
import useIsMobile from "hooks/useIsMobile";
import styles from "./Layout.module.scss"
import BuyWitheCall from "components/BuyWitheCall/BuyWitheCall";

const Header = lazy(() => import("../Header/Header"));
const MobileHeader = lazy(() => import("../MobileHeader/MobileHeader"));
const Footer = lazy(() => import("../Footer/Footer"));
const Navigate = lazy(() => import("../Navigate/Navigate"));
const MobileMenu = lazy(() => import("../MobileMenu/MobileMenu"));

const Layout = ({hiddenFooter}) => {
  const mobile = useIsMobile();
  return (
    <>
      <div className={styles.layoutWrapper}>
        {mobile ? <MobileHeader /> : <Header />}
        {!mobile && <Navigate />}
        <main className={styles.content}>
          <BuyWitheCall />
          <Suspense fallback={<div>Module Loader</div>}>
            <Outlet />
          </Suspense>
        </main>
        {!hiddenFooter && <Footer mobile={mobile} />}
      </div>
      {mobile && <MobileMenu />}
    </>
  );
};

export default Layout;
