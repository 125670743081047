import { createContext } from "react";

import useToggleSpecifiedComponent from "hooks/useToggleSpecifiedComponent";

const ModalContext = createContext({
  isModalOpened: false,
  handleModal: () => {},
  modalContent: null,
});
ModalContext.displayName = "ModalContext";

const ModalProvider = ({ children }) => {
  let {
    isOpened: isModalOpened,
    handle: handleModal,
    dynamicContent: modalContent,
  } = useToggleSpecifiedComponent();

  return (
    <ModalContext.Provider value={{ isModalOpened, handleModal, modalContent }}>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
