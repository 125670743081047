
import ReactDOM from "react-dom/client";

import { ModalProvider } from "./contexts/ModalContext";
import { DropdownProvider } from "./contexts/DropdownContext";
import { CartProvider } from "contexts/CartContext";

import App from "./app/App";
import { LikedProvider } from "contexts/LikedContext";
import { LanguageProvider } from "contexts/LanguageContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <LanguageProvider>
    <CartProvider>
      <LikedProvider>
        <ModalProvider>
          <DropdownProvider>
            <App />
          </DropdownProvider>
        </ModalProvider>
      </LikedProvider>
    </CartProvider>
  </LanguageProvider>
);
