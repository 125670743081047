/** @format */

import { lazy, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";

import LayoutRoot from "components/Layout/LayoutRoot";
import Layout from "components/Layout/Layout/Layout";

import "assets/styles/main.scss";
import { PAGES } from "constants";
import ProtectedRoutes from "./ProtectedRoutes";
import { useLanguage } from "contexts/LanguageContext";
import useIsMobile from "hooks/useIsMobile";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";

const HomePage = lazy(() => import("pages/Home/Home"));
const BuyForMe = lazy(() => import("pages/BuyForMe/BuyForMe"));
const ContactUs = lazy(() => import("pages/ContactUs/ContactUs"));
const PricePolicy = lazy(() => import("pages/PricePolicy/PricePolicy"));
const ComingSoon = lazy(() => import("pages/ComingSoon/ComingSoon"));
const Products = lazy(() => import("pages/Products/Products"));
const ProductShop = lazy(() => import("pages/ShopProduct/ShopProduct"));
const Checkout = lazy(() => import("pages/Checkout/Checkout"));
const PersonalPage = lazy(() => import("pages/PersonalPage/PersonalPage"));
const OrderDetails = lazy(() =>
  import("pages/PersonalPage/SubPages/OrdersDetails/OrdersDetails")
);
const FavoritesPage = lazy(() => import("pages/LikedPage/LikedPage"));
const BasketPage = lazy(() => import("pages/BasketPage/BasketPage"));
const Login = lazy(() => import("pages/Login/Login"));

const App = () => {
  const { language, changeLanguage } = useLanguage();
  const mobile = useIsMobile();
  useEffect(() => {
    changeLanguage
      ? localStorage.setItem("lng", JSON.stringify(language))
      : localStorage.setItem(
          "lng",
          JSON.stringify({ value: "hy-AM", name: "ՀԱՅ" })
        );
  }, [changeLanguage, language]);

  return (
    <Router>
      <Routes>
        <Route element={<LayoutRoot><Layout /></LayoutRoot>}>
          <Route path={PAGES.HOME.HREF} element={<HomePage />} />
          <Route path={PAGES.BUY_FOR_ME.HREF} element={<BuyForMe />} />
          <Route path={PAGES.CONTACT_US.HREF} element={<ContactUs />} />
          <Route path={PAGES.PRICING_POLICY.HREF} element={<PricePolicy />} />
          <Route path={PAGES.SOON.HREF} element={<ComingSoon />} />

          <Route path={PAGES.PRODUCTS.HREF} element={<Products fromHome={true} from="default" />}>
            <Route
              path={":assortmentName/:assortmentId"}
              element={<Products fromHome={true} from="default" />}
            />
            <Route
              path={":assortmentName/:assortmentId/:categoryName/:categoryId"}
              element={<Products fromHome={true} from="default" />}
            />
            <Route
              path={":assortmentName/:assortmentId/:categoryName/:categoryId/:subCategoryName/:subCategoryId"}
              element={<Products fromHome={true} from="default" />} />
          </Route>

          <Route path={PAGES.LIQUID_ASSORTMENT.HREF} element={<Products title={PAGES.LIQUID_ASSORTMENT.TITLE} from="liquid" fromAllProducts={true} /> } />
          <Route path={PAGES.NOVELTY.HREF} element={<Products title={PAGES.NOVELTY.TITLE}  from="default" fromHome={true}/>}>
          <Route
              path={":assortmentName/:assortmentId"}
              element={<Products fromHome={true} from="default" />}
            />
          </Route>
          <Route path={PAGES.SHOP_PRODUCT.HREF} element={<ProductShop />} />
          <Route path={PAGES.CHECKOUT.HREF} element={<Checkout />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />

          <Route path={PAGES.PERSONAL_PAGE.HREF} element={ <ProtectedRoutes> <PersonalPage /> </ProtectedRoutes> } />
          <Route path={PAGES.ORDER_DETAILS.HREF} element={ <ProtectedRoutes> <OrderDetails /> </ProtectedRoutes> } />
        </Route>

        {mobile && (
          <Route element={ <LayoutRoot> <Layout hiddenFooter={true} /> </LayoutRoot> } >
            <Route path={PAGES.FAVORITES.HREF} element={<FavoritesPage />} />
            <Route path={PAGES.BASKET.HREF} element={<BasketPage />} />
            <Route path={PAGES.LOGIN.HREF} element={<Login />} />
          </Route>
        )}

        <Route path={PAGES.NOT_FOUND.HREF} element={<div>PAGE NOT FOUND</div>} />
      </Routes>
    </Router>
  );
};

export default App;
