// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BuyWitheCall_linkWrapper__9CcEM {
  width: -moz-fit-content;
  width: fit-content;
  aspect-ratio: 1/1;
  padding: 0.8rem 0.6rem;
  border-radius: 50%;
  background: linear-gradient(125deg, rgb(255, 101, 107) 0%, rgb(243, 162, 37) 100%);
  box-shadow: 0px 0px 17px #ff9c92;
  position: fixed;
  bottom: 4rem;
  right: 3rem;
  z-index: var(--z-index-3);
}
.BuyWitheCall_linkWrapper__9CcEM span {
  color: white;
  text-align: center;
}

.BuyWitheCall_mobileVers__sJgd8 {
  bottom: 8.8rem;
  right: 4.466vw;
}`, "",{"version":3,"sources":["webpack://./src/components/BuyWitheCall/BuyWitheCall.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EAAA,kBAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,kFAAA;EAKA,gCAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;AAHF;AAKE;EACE,YAAA;EACA,kBAAA;AAHJ;;AAOA;EACE,cAAA;EACA,cAAA;AAJF","sourcesContent":[".linkWrapper {\n  width: fit-content;\n  aspect-ratio: 1/1;\n  padding: 0.8rem 0.6rem;\n  border-radius: 50%;\n  background: linear-gradient(\n    125deg,\n    rgb(255, 101, 107) 0%,\n    rgb(243, 162, 37) 100%\n  );\n  box-shadow: 0px 0px 17px #ff9c92;\n  position: fixed;\n  bottom: 4rem;\n  right: 3rem;\n  z-index: var(--z-index-3);\n\n  span {\n    color: white;\n    text-align: center;\n  }\n}\n\n.mobileVers {\n  bottom: 8.8rem;\n  right: 4.466vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkWrapper": `BuyWitheCall_linkWrapper__9CcEM`,
	"mobileVers": `BuyWitheCall_mobileVers__sJgd8`
};
export default ___CSS_LOADER_EXPORT___;
