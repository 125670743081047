import React from "react";
import styles from "./PrivacyPolicy.module.scss";

const PrivacyPolicy = () => {
  return (
    <div className={`flex flex-column ${styles.pageWrapper}`}>
      <div className={`flex flex-column ${styles.headingBlock}`}>
        <h2 className="fz-32-bold">ԳԱՂՏՆԻՈՒԹՅԱՆ ՔԱՂԱՔԱԿԱՆՈՒԹՅՈՒՆ</h2>
        <h3 className="fz-24-bold">
          «Մարար Գրուպ» Սահմանափակ Պատասխանատվությամբ Ընկերության Գաղտնիության
          Քաղաքականություն
        </h3>
      </div>
      <div className={`flex flex-column ${styles.block}`}>
        <h4 className="fz-24-bold">Ընդհանուր Դրույթներ</h4>
        <p className="fz-16">
          Սույն գաղտնիության քաղաքականությունը գործում է այն տեղեկատվության
          նկատմամբ, որը «Մարար Գրուպ» սահմանափակ պատասխանատվությամբ ընկերությունը
          կարող է ստանալ Ընկերությանը պատկանող www.shopnook.am
          կայքից&nbsp;&nbsp;(այսուհետ՝ նաև «Առցանց խանութ») օգտվողի (այսուհետ՝
          նաև «Օգտատեր») (Ընկերությունը և Օգտատերը միասին հիշատակվելիս՝ նաև
          «Կողմեր») վերաբերյալ (այսուհետ՝ նաև «Գաղտնիության քաղաքականություն»):
          <br />
          Ընկերությունը չի վերահսկում և պատասխանատվություն չի կրում երրորդ
          անձանց կայքերի և/կամ բջջային հավելվածների համար, որոնց Օգտատերը կարող
          է անցնել Առցանց խանութում առկա հղումներով։
          <br />
          Գրանցվելով Առցանց խանութում և Ընկերությանը փոխանցելով իր անձնական
          տվյալները՝ Օգտատերն անվերապահորեն համաձայնվում է սույն քաղաքականության
          և դրա պայմանների հետ: Գաղտնիության քաղաքականության որևէ պայմանի հետ
          համաձայն չլինելու դեպքում Օգտատերը պետք է զերծ մնա Առցանց խանութից
          օգտվելուց:
          <br />
          Ընկերությունն Օգտատիրոջ անձնական տվյալները հավաքում է այն ժամանակ, երբ
          վերջինս դրանք նշում է Առցանց խանութում, ուղարկում է էլեկտրոնային
          փոստով կամ տեղեկացնում անձամբ: Անձնական տվյալների պաշտպանության մասին
          գործող օրենսդրության համաձայն՝ Օգտատերը կարող է ցանկացած ժամանակ
          պահանջել փոփոխել, թարմացնել կամ հեռացնել իր անձնական տվյալները:
        </p>
      </div>
      <div className={`flex flex-column ${styles.block}`}>
        <h4 className="fz-24-bold">Սկզբունքներ</h4>
        <p className="fz-16">
          &#x2022;&nbsp;&nbsp; տվյալների որակը.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;անձնական տվյալները պետք է.
          <br />
          &#x2022;&nbsp;&nbsp; ձեռք բերվեն և մշակվեն արդար ու օրինական
          ճանապարհով,
          <br />
          &#x2022;&nbsp;&nbsp; պահպանվեն հատուկ և օրինական նպատակների համար ու
          չօգտագործվեն այլ նպատակներով,
          <br />
          &#x2022;&nbsp;&nbsp; լինեն համարժեք, համապատասխան և չգերազանցեն դրանց
          պահպանման նպատակների սահմանները,
          <br />
          &#x2022;&nbsp;&nbsp; լինեն ճշգրիտ և անհրաժեշտության դեպքում պահվեն
          թարմացված վիճակում,
          <br />
          &#x2022;&nbsp;&nbsp; պահպանվեն այնպիսի եղանակով, որը թույլ է տալիս
          նույնականացնել տվյալների սուբյեկտներին` ոչ ավելին, քան անհրաժեշտ է այդ
          տվյալների պահպանման նպատակներով:
          <br />
          &#x2022;&nbsp;&nbsp; տվյալների անվտանգությունը.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;համապատասխան անվտանգության
          միջոցներ պետք է ձեռնարկվեն ընկերության մոտ պահվող անձնական տվյալները
          պատահական կամ
          չթույլատրված&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          ոչնչացումից կամ պատահական կորստից, ինչպես նաև չթույլատրված մուտքից,
          փոփոխումից կամ տարածումից պաշտպանելու համար:
          <br />
          &#x2022;&nbsp;&nbsp; տվյալների սուբյեկտների լրացուցիչ երաշխիքները.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; օգտատերը պետք է հնարավորություն ունենա.
          <br />
          &#x2022;&nbsp;&nbsp; պարզելու անձնական տվյալների ավտոմատացված ֆայլի
          գոյությունը, դրա հիմնական նպատակները, ինչպես նաև ֆայլի վերահսկիչի
          ինքնությունը, բնակության և աշխատանքի հիմնական վայրերը,
          <br />
          &#x2022;&nbsp;&nbsp; ողջամիտ պարբերականությամբ և առանց ավելորդ
          հետաձգման կամ ծախսի ստանալու հաստատում, թե արդյոք իրեն առնչվող
          անձնական տվյալները պահպանվում են ավտոմատացված տվյալների ֆայլում,
          ինչպես նաև ստանալու այդ տվյալները իր համար մատչելի ձևով,
          <br />
          անհրաժեշտության դեպքում հասնելու այդ տվյալների ուղղմանը կամ
          ոչնչացմանը, եթե դրանք մշակվել են օրենքով սահմանված դրույթների
          խախտմամբ,
          <br />
          &#x2022;&nbsp;&nbsp; օգտվելու իրավական պաշտպանությունից այն դեպքերում,
          երբ հաստատման հարցումը կամ, կախված հանգամանքներից, սույն կետի 2-րդ և
          3-րդ պարբերություններում նշված տվյալների ստանալը, ուղղումը կամ
          ոչնչացումը չեն կատարվել:
        </p>
      </div>
      <div className={`flex flex-column ${styles.block}`}>
        <h4 className="fz-24-bold">Հիմնական Հասկացություններ</h4>
        <p className="fz-16">
          &#x2022;&nbsp;&nbsp; անձնական տվյալ - օգտատիրոջը վերաբերող ցանկացած
          տեղեկություն, որը թույլ է տալիս կամ կարող է թույլ տալ ուղղակի կամ
          անուղղակի կերպով նույնականացնել անձի ինքնությունը,
          <br />
          &#x2022;&nbsp;&nbsp; անձնական տվյալների մշակում - անկախ իրականացման
          ձևից և եղանակից (այդ թվում` ավտոմատացված, տեխնիկական ցանկացած միջոցներ
          կիրառելու կամ առանց դրանց) ցանկացած գործողություն կամ
          գործողությունների խումբ, որը կապված է անձնական տվյալները հավաքելու կամ
          ամրագրելու կամ մուտքագրելու կամ համակարգելու կամ կազմակերպելու կամ
          պահպանելու կամ օգտագործելու կամ վերափոխելու կամ վերականգնելու կամ
          փոխանցելու կամ ուղղելու կամ ուղեփակելու կամ ոչնչացնելու կամ այլ
          գործողություններ կատարելու հետ,
          <br />
          &#x2022;&nbsp;&nbsp; անձնական տվյալների փոխանցում երրորդ անձանց -
          անձնական տվյալները որոշակի կամ անորոշ շրջանակի այլ անձանց փոխանցելուն
          կամ դրանց հետ ծանոթացնելուն ուղղված գործողություն, այդ թվում`
          զանգվածային լրատվության միջոցներով անձնական տվյալները հրապարակելը,
          տեղեկատվական հաղորդակցման ցանցերում տեղադրելը կամ այլ եղանակով
          անձնական տվյալներն այլ անձի մատչելի դարձնելը,
          <br />
          &#x2022;&nbsp;&nbsp; անձնական տվյալների օգտագործում - անձնական
          տվյալների հետ կատարվող գործողություն, որի ուղղակի կամ անուղղակի
          նպատակը կարող է լինել որոշումներ ընդունելը կամ կարծիք ձևավորելը կամ
          իրավունքներ ձեռք բերելը կամ իրավունքներ կամ արտոնություններ տրամադրելը
          կամ իրավունքները սահմանափակելը կամ զրկելը կամ այլ նպատակի իրագործումը,
          որոնք տվյալների սուբյեկտի կամ երրորդ անձանց համար առաջացնում կամ կարող
          են առաջացնել իրավական հետևանքներ կամ այլ կերպ առնչվել նրանց
          իրավունքներին ու ազատություններին,
          <br />
          &#x2022;&nbsp;&nbsp; անձնական տվյալների ոչնչացում - գործողություն, որի
          արդյունքում հնարավոր չէ վերականգնել տեղեկատվական համակարգում առկա
          անձնական տվյալների բովանդակությունը,
          <br />
          &#x2022;&nbsp;&nbsp; օգտատեր - ընկերությանը պատկանող կայքը և/կամ
          բջջային հավելվածն օգտագործող չափահաս ֆիզիկական անձ,
          <br />
          &#x2022;&nbsp;&nbsp; կայք - https://www.shopnook.am հասցեում գտնվող
          «www.shopnook.am» կայքը,
          <br />
          &#x2022;&nbsp;&nbsp; անձնական տվյալների պաշտպանություն - տեխնիկական,
          կազմակերպչական և կազմակերպա-տեխնիկական բնույթի միջոցառումների համալիր,
          որոնք ուղղված են որոշակի կամ նման տեղեկատվության հիման վրա որոշվող
          անձնական տվյալների սուբյեկտին վերաբերող տեղեկությունների
          պաշտպանությանը:
        </p>
      </div>
      <div className={`flex flex-column ${styles.block}`}>
        <h4 className="fz-24-bold">
          ԱՆձնական Տվյալներ, Որոնք ստանում Եվ Մշակում է Ընկերությունը
        </h4>
        <p className="fz-16">
          սույն քաղաքականության շրջանակներում օգտատիրոջ անձնական տվյալներն են՝
          անձնական տեղեկություններ, որոնք օգտատերը տրամադրում է իր մասին
          ինքնուրույն առցանց խանութում գրանցվելիս կամ ընկերության կողմից
          մատուցված ծառայություններից օգտվելու ընթացքում, մասնավորապես.
          <br />
          <br />
          &#x2022;&nbsp;&nbsp; սույն քաղաքականության շրջանակներում օգտատիրոջ
          անձնական տվյալներն են՝ անձնական տեղեկություններ, որոնք օգտատերը
          տրամադրում է իր մասին ինքնուրույն առցանց խանութում գրանցվելիս կամ
          ընկերության կողմից մատուցված ծառայություններից օգտվելու ընթացքում,
          մասնավորապես.
          <br />
          &#x2022;&nbsp;&nbsp; հեռախոսահամարը,
          <br />
          &#x2022;&nbsp;&nbsp; անուն, ազգանունը,
          <br />
          &#x2022;&nbsp;&nbsp; հասցեն,
          <br />
          &#x2022;&nbsp;&nbsp; էլեկտրոնային փոստի հասցեն,
          <br />
          &#x2022;&nbsp;&nbsp; ծննդյան ամսաթիվը,
          <br />
          &#x2022;&nbsp;&nbsp; բանկային ռեկվիզիտները,
          <br />
          &#x2022;&nbsp;&nbsp; առցանց խանութում օգտատիրոջ կատարած գնումների
          պատմությունը,
          <br />
          &#x2022;&nbsp;&nbsp; առցանց խանութում օգտատիրոջ նախընտրած ապրանքների
          ցանկը,
          <br />
          &#x2022;&nbsp;&nbsp; առաքման հասցեները և առաքման համար օգտատիրոջ
          կողմից տրամադրված այլ տեղեկատվությունը,
          <br />
          &#x2022;&nbsp;&nbsp; օգտատիրոջ էլեկտրոնային և այլ նամակագրությունը
          ընկերության, ընկերության աշխատողների և օգտատիրոջ պատվերի ձևակերպման և
          կատարման հետ առնչվող այլ անձանց միջև,
          <br />
          &#x2022;&nbsp;&nbsp; այլ ցանկացած տեղեկատվություն, որն անհրաժեշտ է
          օգտատիրոջ պատվերի ձևակերպման և կատարման համար:
          <br />
          <br />
          օգտագործման տվյալները.
          <br />
          տվյալները, որոնք ավտոմատ կերպով փոխանցվում են ընկերությանը առցանց
          խանութից օգտվելու ընթացքում, օգտատիրոջ սարքի (բջջային հեռախոս,
          համակարգիչ և այլն) վրա տեղադրված ծրագրային ապահովման միջոցով: դրանք
          են.
          <br />
          <br />
          &#x2022;&nbsp;&nbsp; օգտատիրոջ կողմից առցանց խանութում կատարված
          գործողությունները, այցելության ժամանակահատվածը,
          <br />
          &#x2022;&nbsp;&nbsp; օգտատիրոջ գտնվելու վայրը,
          <br />
          &#x2022;&nbsp;&nbsp; օպերացիոն համակարգը,
          <br />
          &#x2022;&nbsp;&nbsp; բրաուզերի տեսակը,
          <br />
          &#x2022;&nbsp;&nbsp; սարքի տեսակը,
          <br />
          &#x2022;&nbsp;&nbsp; ip հասցեն,
          <br />
          &#x2022;&nbsp;&nbsp; cookie-ների այլ տվյալներ:
        </p>
      </div>
      <div className={`flex flex-column ${styles.block}`}>
        <h4 className="fz-24-bold">
          Օգտատիրոջ Անձնական Տվյալների Հավաքման Եվ Մշակման Նպատակները
        </h4>
        <p className="fz-16">
          Ընկերությունը հավաքում և պահպանում է միայն այն անձնական տվյալները,
          որոնք անհրաժեշտ են ծառայությունների մատուցման և/կամ Օգտատիրոջ հետ
          կնքված պայմանագրերի կատարման համար:
          <br />
          Ընկերությունն օգտագործում է Օգտատիրոջ անձնական տվյալները հետևյալ
          նպատակներով.
          <br />
          &#x2022;&nbsp;&nbsp; Օգտատիրոջ նույնականացում,
          <br />
          &#x2022;&nbsp;&nbsp; Օգտատիրոջը ծառայությունների անհատականացված
          ռեսուրսների հասանելիության տրամադրում,
          <br />
          &#x2022;&nbsp;&nbsp; Օգտատիրոջ հետ հետադարձ կապի հաստատում, ներառյալ
          Առցանց խանութի օգտագործման հետ կապված ծանուցումների, հարցումների
          ուղղում, Օգտատիրոջ կողմից ուղարկված հարցումների և հայտերի մշակում,
          պատվերների առաքում,
          <br />
          &#x2022;&nbsp;&nbsp; Օգտատիրոջ հետ պայմանագրերի կնքում, կատարում և
          պատվերների ձևակերպում,
          <br />
          &#x2022;&nbsp;&nbsp; Օգտատիրոջ գտնվելու վայրի որոշում անվտանգության
          ապահովման նպատակով,
          <br />
          &#x2022;&nbsp;&nbsp; Օգտատիրոջ կողմից տրամադրված անձնական տվյալների
          հավաստիության և ամբողջականության հաստատում,
          <br />
          &#x2022;&nbsp;&nbsp; Առցանց խանութի օգտագործման հետ կապված խնդիրների
          առաջացման դեպքում Օգտատիրոջը արդյունավետ տեխնիկական աջակցության
          տրամադրում,
          <br />
          &#x2022;&nbsp;&nbsp; Օգտատիրոջ համաձայնությամբ վերջինիս գովազդային
          հաղորդագրությունների ուղարկում,
          <br />
          &#x2022;&nbsp;&nbsp; Առցանց խանութի աշխատանքի որակի բարելավում, դրանց
          օգտագործման հարմարավետություն, նոր ծառայությունների մշակում,
          <br />
          &#x2022;&nbsp;&nbsp; այլ նպատակներով, որոնք կարող են նախատեսվել
          Կողմերի միջև կնքված պայմանագրով, ինչպես նաև ՀՀ օրենսդրությամբ:
        </p>
      </div>
      <div className={`flex flex-column ${styles.block}`}>
        <h4 className="fz-24-bold">
          Անձնական Տվյալների Մշակման Եվ Երրորդ Անձանց Փոխանցման Պայմանները
        </h4>
        <p className="fz-16">
          Օգտատերերի անձնական տվյալների մշակումն իրականացվում է «Անձնական
          տվյալների պաշտպանության մասին» ՀՀ օրենքին համապատասխան:
          <br />
          Օգտատիրոջ անձնական տվյալների մշակումն իրականացվում է առանց ժամկետի
          սահմանափակման, ցանկացած օրինական եղանակով, այդ թվում՝ անձնական
          տվյալների տեղեկատվական համակարգերում՝ ավտոմատացման միջոցների
          օգտագործմամբ կամ առանց նման միջոցների օգտագործման:
          <br />
          Օգտատիրոջ ամբողջ անձնական տվյալների նկատմամբ պահպանվում է
          գաղտնիությունը և ապահովվում անվտանգությունը:
          <br />
          Ընկերությունն իրավունք ունի Օգտատիրոջ անձնական տվյալները փոխանցել
          երրորդ անձանց՝ այդ թվում սուրհանդակային և փոստային կապի
          ծառայություններ մատուցողներին և այլ անձանց, հետևյալ դեպքերում.
          <br />
          &#x2022;&nbsp;&nbsp; Օգտատերը նման գործողությունների կատարման համար
          տվել է իր համաձայնությունը,
          <br />
          &#x2022;&nbsp;&nbsp; փոխանցումն անհրաժեշտ է Օգտատիրոջ կողմից Առցանց
          խանութից օգտվելու կամ Օգտատիրոջ հետ կնքված պայմանագրի կատարման համար,
          <br />
          &#x2022;&nbsp;&nbsp; Ընկերության իրավունքների և օրինական շահերի
          պաշտպանությունն ապահովելու նպատակով,
          <br />
          &#x2022;&nbsp;&nbsp; օրենքով սահմանված այլ դեպքերում:
          <br />
          <br />
          Առցանց խանութը կարող է պարունակել հղումներ այլ կայքերի, որոնք չեն
          կառավարվում Ընկերության կողմից: Եթե Օգտատերն անցնում է հղումով, ապա նա
          հայտնվում է երրորդ անձին պատկանող կայքում: Ընկերությունը խորհուրդ է
          տալիս Օգտատիրոջը յուրաքանչյուր կայքում, որը նա այցելում է, առանձին
          ծանոթանալ տվյալ կայքի գաղտնիության քաղաքականությանը: Ընկերությունը
          չունի վերահսկողություն և չի ստանձնում պատասխանատվություն երրորդ անձանց
          կայքերի և/կամ դրանց պայմանների բովանդակության, գաղտնիության
          քաղաքականության կամ գործողությունների համար:
        </p>
      </div>
      <div className={`flex flex-column ${styles.block}`}>
        <h4 className="fz-24-bold">
          Օգտատիրոջ Անձնական Տվյալների Պաշտպանության Համար Կիրառվող Միջոցները
        </h4>
        <pre className="fz-16">
          Անձնական տվյալների մշակման ժամանակ Ընկերությունը ձեռնարկում է
          անհրաժեշտ իրավական, կազմակերպչական և տեխնիկական միջոցները անձնական
          տվյալների պաշտպանության համար, մասնավորապես՝ ոչ իրավաչափ կամ պատահական
          հասանելիությունից, ոչնչացումից, փոփոխությունից, արգելափակումից,
          կրկնօրինակումից, անձնական տվյալների տրամադրումից, տարածումից, ինչպես
          նաև Օգտատիրոջ անձնական տվյալների նկատմամբ այլ ոչ իրավաչափ
          գործողություններից՝ համաձայն անձնական տվյալների պաշտպանության
          վերաբերյալ օրենսդրության պահանջների:
        </pre>
      </div>
      <div className={`flex flex-column ${styles.block}`}>
        <h4 className="fz-24-bold">
          Կողմերի Իրավունքները Եվ Պարտականությունները
        </h4>
        <p className="fz-16">
          Օգտատերն իրավունք ունի.
          <br />
          &#x2022;&nbsp;&nbsp; «Անձնական տվյալների պաշտպանության մասին» օրենքով
          սահմանված կարգով և ծավալներով ստանալ իր անձնական տվյալների մշակման
          վերաբերյալ տեղեկատվություն,
          <br />
          &#x2022;&nbsp;&nbsp; Ընկերությունից պահանջել անձնական տվյալների
          հստակեցում, արգելափակում կամ ոչնչացում այն դեպքում, երբ անձնական
          տվյալները թերի են, հնացած, պարունակում են անճշտություններ, ձեռք են
          բերվել և օգտագործվում են հայտարարված նպատակների և օրենսդրությամբ
          սահմանված պահանջների խախտումներով,
          <br />
          &#x2022;&nbsp;&nbsp; հետ կանչել անձնական տվյալների մշակման համար տրված
          համաձայնությունը:
          <br />
          &#x2022;&nbsp;&nbsp; Օգտատերը պետք է հաշվի առնի, որ անձնական տվյալների
          տրամադրումից հրաժարվելը, անձնական տվյալների մշակման համար Ընկերությանը
          համաձայնություն տրամադրելուց հրաժարվելը կամ նախկինում տրված
          համաձայնությունը հետ կանչելը կարող են հանգեցնել Օգտատիրոջ նկատմամբ
          Ընկերության ունեցած պարտավորությունների հետագա կատարման
          անհնարինությանը:
          <br />
          Օգտատերը պարտավոր է.
          <br />
          &#x2022;&nbsp;&nbsp; Ընկերությանը տրամադրել Առցանց խանութից օգտվելու
          համար անհրաժեշտ հավաստի տեղեկատվություն,
          <br />
          &#x2022;&nbsp;&nbsp; տեղեկացնել Ընկերությանը իր անձնական տվյալների
          փոփոխության մասին ոչ ուշ, քան իր կողմից Առցանց խանութի միջոցով
          հերթական պատվերի ձևակերպումը:
          <br />
          Ընկերությունն իրավունք ունի.
          <br />
          &#x2022;&nbsp;&nbsp; իրականացնել Ընկերության կողմից օրինական
          ճանապարհով ստացված անձնական տվյալների մշակում՝ սույն քաղաքականությամբ
          սահմանված նպատակների համար,
          <br />
          &#x2022;&nbsp;&nbsp; սահմանափակել Օգտատիրոջ հասանելիությունը իր
          անձնական տվյալներին այն դեպքում, եթե հասանելիությունը խախտում է երրորդ
          անձանց իրավունքներն ու օրինական շահերը:
          <br />
          Ընկերությունը պարտավոր է.
          <br />
          &#x2022;&nbsp;&nbsp; Օգտատիրոջ պահանջով նրան տրամադրել «Անձնական
          տվյալների պաշտպանության մասին» օրենքի 15-րդ հոդվածով նախատեսված
          տեղեկատվությունը,
          <br />
          &#x2022;&nbsp;&nbsp; ոչ ամբողջական, ոչ ճշգրիտ, հնացած, անօրինական
          ճանապարհով ձեռք բերված կամ մշակման նպատակներին հասնելու համար ոչ
          անհրաժեշտ անձնական տվյալների դեպքում իրականացնել անհրաժեշտ
          գործողություններ դրանք ամբողջականացնելու, թարմացնելու, ուղղելու կամ
          ոչնչացնելու ուղղությամբ,
          <br />
          &#x2022;&nbsp;&nbsp; օգտագործել ստացված անձնական տվյալները բացառապես
          Գաղտնիության քաղաքականության մեջ նշված նպատակներով,
          <br />
          &#x2022;&nbsp;&nbsp; ապահովել Օգտատիրոջ անձնական տվյալների
          գաղտնիությունը,
          <br />
          &#x2022;&nbsp;&nbsp; կատարել մշակման վերաբերյալ Գաղտնիության
          քաղաքականությամբ և ՀՀ օրենսդրությամբ նախատեսված այլ
          պարտականություններ:
        </p>
      </div>
      <div className={`flex flex-column ${styles.block}`}>
        <h4 className="fz-24-bold">Վեճերի Լուծման Կարգը</h4>
        <p className="fz-16">
          Սույն գաղտնիության քաղաքականության կապակցությամբ ծագած ցանկացած վեճ
          լուծվում է բանակցությունների միջոցով:
          <br />
          Կողմերը փոխադարձ համաձայնությամբ Գաղտնիության քաղաքականությունից բխող
          վեճերի լուծման եղանակ են սահմանում նախապահանջային եղանակը:
          <br />
          Խախտում վկայակոչող կողմը ենթադրյալ խախտում թույլ տված կողմին խախտման
          վերաբերյալ ներկայացնում է գրավոր նախապահանջ: Նախապահանջը ստանալու
          պահից 15 օրացուցային օրվա ընթացքում դրա վերաբերյալ պատասխան չստացվելու
          կամ ներկայացված պահանջը մերժվելու դեպքում վեճը կարող է հանձնվել
          Հայաստանի Հանրապետության իրավասու դատարանի քննությանը:
          <br />
          Գաղտնիության քաղաքականության նկատմամբ կիրառելի իրավունք է հանդիսանում
          ՀՀ օրենսդրությունը:
        </p>
      </div>
      <div className={`flex flex-column ${styles.block}`}>
        <h4 className="fz-24-bold">ԱՅԼ ԴՐՈՒՅԹՆԵՐ</h4>
        <p className="fz-16">
          Գաղտնիության քաղաքականությունն ուժի մեջ է մտնում Օգտատիրոջ կողմից
          Առցանց խանութում գրանցման պահից և հանդիսանում է Ընկերությանը պատկանող
          Առցանց խանութից օգտվելու վերաբերյալ օգտագործման համաձայնագրի
          անբաժանելի մասը: Գրանցվելով Առցանց խանութում՝ Օգտատերն հավաստում է, որ
          ամբողջությամբ ծանոթացել է Գաղտնիության քաղաքականության բովանդակությանը
          և որևէ առարկություն չունի դրա վերաբերյալ:
          <br />
          Ընկերությունն իրավունք ունի ցանկացած պահի միակողմանի փոփոխել
          Գաղտնիության քաղաքականությունը: Գաղտնիության քաղաքականության նոր
          խմբագրությունն ուժի մեջ է մտնում Ընկերության կողմից այն Առցանց
          խանութում տեղադրելու պահից, եթե այլ բան նախատեսված չէ Գաղտնիության
          քաղաքականության նոր խմբագրությամբ: Յուրաքանչյուր պատվեր Առցանց խանութի
          միջոցով ձևակերպելիս՝ Օգտատերն հավաստում է, որ ծանոթացել և համաձայն է
          տվյալ պահին Առցանց խանութում առկա Գաղտնիության քաղաքականության
          խմբագրությանը:
          <br />
          Գաղտնիության քաղաքականության որևէ մասի գործողության դադարեցումը,
          չեղյալ հայտարարելը կամ անվավերությունը չի հանգեցնում մյուս մասերի
          դադարեցմանը, չեղյալ հայտարարելուն կամ անվավերությանը:
          <br />
          Գաղտնիության քաղաքականությունը կազմված է հայերենով, ռուսերենով և
          անգլերենով և պարտադիր է Կողմերի համար։ Հայերեն, ռուսերեն և անգլերեն
          տեքստերի միջև տարընթերցումների դեպքում առավելությունը տրվում է
          Գաղտնիության քաղաքականության հայերեն տարբերակին:
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
