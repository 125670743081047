import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const AXIOS_INSTANCE = axios.create({
  baseURL: apiUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",

  },
  withCredentials: true,
});

export default AXIOS_INSTANCE;
