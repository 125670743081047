import React, { createContext, useContext, useState } from "react";

const LikedContext = createContext();

const useLiked = () => {
  const context = useContext(LikedContext);
  if (!context) {
    throw new Error("useLiked must be used within a LikedProvider");
  }
  return context;
};

const LikedProvider = ({ children }) => {
  const [lickedItems, setLikedItems] = useState(
    JSON.parse(localStorage.getItem("likedData")) || []
  );

  const addToLiked = (item) => {
    const itemIndex = lickedItems.findIndex(
      (product) => product.id === item.id
    );
    if (itemIndex === -1) {
      const newItems = [...lickedItems, item];
      setLikedItems(newItems);
      localStorage.setItem("likedData", JSON.stringify(newItems));
    } else {
      removeItem(item.id);
    }
  };

  const removeItem = (itemId) => {
    const newCartItems = lickedItems.filter((item) => item.id !== itemId);
    setLikedItems(newCartItems);
    localStorage.setItem("likedData", JSON.stringify(newCartItems));
  };

  const updateLiked = (item) => {
    const itemIndex = lickedItems.findIndex(
      (product) => product.id === item.id
    );
    const updatedItem = { ...lickedItems[itemIndex], ...item };
    const newItems = [...lickedItems];
    newItems[itemIndex] = updatedItem;
    setLikedItems(newItems);
    localStorage.setItem("likedData", JSON.stringify(newItems));
  };

  return (
    <LikedContext.Provider
      value={{ lickedItems, addToLiked, removeItem, updateLiked }}
    >
      {children}
    </LikedContext.Provider>
  );
};

export { useLiked, LikedProvider };
