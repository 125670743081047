// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacyPolicy_pageWrapper__2nam8 {
  gap: 2.4rem;
  padding: 2.4rem 4.466vw 6.4rem;
}
.PrivacyPolicy_pageWrapper__2nam8 .PrivacyPolicy_headingBlock__CJJzs {
  gap: 1.6rem;
}
.PrivacyPolicy_pageWrapper__2nam8 .PrivacyPolicy_block__TKUsh {
  gap: 0.8rem;
}
.PrivacyPolicy_pageWrapper__2nam8 .PrivacyPolicy_block__TKUsh p br {
  padding: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/PrivacyPolicy/PrivacyPolicy.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,8BAAA;AACF;AAAE;EACE,WAAA;AAEJ;AAAE;EACE,WAAA;AAEJ;AAAM;EACE,eAAA;AAER","sourcesContent":[".pageWrapper {\n  gap: 2.4rem;\n  padding: 2.4rem 4.466vw 6.4rem;\n  .headingBlock {\n    gap: 1.6rem;\n  }\n  .block {\n    gap: 0.8rem;\n    p {\n      br{\n        padding: .8rem;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrapper": `PrivacyPolicy_pageWrapper__2nam8`,
	"headingBlock": `PrivacyPolicy_headingBlock__CJJzs`,
	"block": `PrivacyPolicy_block__TKUsh`
};
export default ___CSS_LOADER_EXPORT___;
